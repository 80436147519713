@import url('https://use.typekit.net/mxp4qiv.css');
/* Satoshi Fonts */
/* @font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-Black.otf') format('opentype');
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  font-style: normal;
}


@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-Bold.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('../fonts/Satoshi-BlackItalic.otf') format('opentype');
  font-weight: 900;
  font-style: italic;
}
*/

@font-face {
  font-family: 'Borna';
  src: url('../fonts/Borna-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Borna';
  font-weight: 700;
  src: url(../fonts/borna-bold-webfont.ttf) format('truetype');
}

body {
  /* font-family: 'Satoshi', sans-serif; */
  font-family: 'montserrat', sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 17px;
  line-height: 24px;
  --tw-text-opacity: 1;
  color: rgb(22 28 45 / var(--tw-text-opacity));
}
:is(.dark body) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

p {
  line-height: 1.625;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: 'montserrat', sans-serif;
  font-weight: 500;
  font-style: normal;
  text-transform: uppercase;
}

::-moz-selection {
  background-color: rgb(127 127 127 / 0.9);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

::selection {
  background-color: rgb(127 127 127 / 0.7);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

/********************/
/*     Buttons      */
/*==================*/
/* Note: If you want to add your own colors, you can do so by going to this tailwind.config.js file  */

.btn {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  vertical-align: middle;
  font-size: 17px;
  line-height: 24px;
  font-weight: 500;
}
.btn:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.btn.btn-lg {
  height: 3.5rem;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.btn.btn-sm {
  height: 1.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}
.btn.btn-icon {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 2.5rem;
  width: 2.5rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 0px;
  text-align: center;
  font-size: 17px;
  line-height: 24px;
}
.btn.btn-icon.btn-lg {
  height: 3.5rem;
  width: 3.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.btn.btn-icon.btn-sm {
  height: 1.75rem;
  width: 1.75rem;
}
.btn.btn-link {
  position: relative;
  height: 1.75rem;
  border-style: none;
  padding: 0px;
}
.btn.btn-link::after {
  position: absolute;
  inset-inline-end: 0px;
  bottom: 0px;
  inset-inline-start: 0px;
  height: 1px;
  width: 0px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  --tw-content: '';
  content: var(--tw-content);
}
.btn.btn-link:hover::after {
  inset-inline-end: auto;
  content: var(--tw-content);
  width: 100%;
}

/*********************************/
/*         Menu                  */
/*===============================*/
#topnav {
  position: fixed;
  inset-inline-start: 0px;
  inset-inline-end: 0px;
  top: 0px;
  z-index: 999;
  border-width: 0px;
  background-color: transparent;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
#topnav .logo {
  --tw-text-opacity: 1 !important;
  color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
}
:is([dir='ltr'] #topnav .logo) {
  float: left !important;
}
:is([dir='rtl'] #topnav .logo) {
  float: right !important;
}
:is(.dark #topnav .logo) {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
#topnav .logo .l-dark {
  display: none;
}
#topnav .logo .l-light {
  display: inline-block;
}
#topnav .logo:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
#topnav .has-submenu {
  position: relative;
}
#topnav .has-submenu.active a {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
:is(.dark #topnav .has-submenu.active a) {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
#topnav .has-submenu.active .submenu li.active > a {
  --tw-text-opacity: 1 !important;
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
:is(.dark #topnav .has-submenu.active .submenu li.active > a) {
  --tw-text-opacity: 1 !important;
  font-weight: 900;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
#topnav .has-submenu.active.active .menu-arrow {
  --tw-border-opacity: 1;
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
:is(.dark #topnav .has-submenu.active.active .menu-arrow) {
  --tw-border-opacity: 1;
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
#topnav .has-submenu .submenu .submenu-arrow {
  border-width: 0px 0.125rem 0.125rem 0px;
  position: absolute;
  inset-inline-end: 1.25rem;
  top: 0.8125rem;
  display: inline-block;
  --tw-border-opacity: 1;
  border-color: rgb(22 28 45 / var(--tw-border-opacity));
  padding: 2px;
}
:is([dir='ltr'] #topnav .has-submenu .submenu .submenu-arrow) {
  --tw-rotate: -45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
:is([dir='rtl'] #topnav .has-submenu .submenu .submenu-arrow) {
  --tw-rotate: 135deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
:is(.dark #topnav .has-submenu .submenu .submenu-arrow) {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
#topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}
:is(.dark #topnav .has-submenu .submenu .has-submenu:hover > .submenu-arrow) {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}
#topnav
  .has-submenu
  .submenu
  .has-submenu
  .submenu
  .has-submenu:hover
  .submenu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}
:is(
    .dark
      #topnav
      .has-submenu
      .submenu
      .has-submenu
      .submenu
      .has-submenu:hover
      .submenu-arrow
  ) {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}
#topnav
  .has-submenu
  .submenu
  .has-submenu
  .submenu
  .has-submenu:hover
  > .submenu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}
:is(
    .dark
      #topnav
      .has-submenu
      .submenu
      .has-submenu
      .submenu
      .has-submenu:hover
      > .submenu-arrow
  ) {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
}
#topnav .navbar-toggle {
  position: relative;
  margin: 0px;
  cursor: pointer;
  border-width: 0px;
  padding: 0px;
}
#topnav .navbar-toggle .lines {
  position: relative;
  margin-top: 30px;
  -webkit-margin-end: 0px;
  margin-inline-end: 0px;
  margin-bottom: 26px;
  -webkit-margin-start: 10px;
  margin-inline-start: 10px;
  display: block;
  height: 18px;
  width: 25px;
}
#topnav .navbar-toggle span {
  -webkit-transition: -webkit-transform 0.5s ease;
  transition: -webkit-transform 0.5s ease;
  transition: transform 0.5s ease;
  transition: transform 0.5s ease, -webkit-transform 0.5s ease;
  margin-bottom: 5px;
  display: block;
  height: 2px;
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
:is(.dark #topnav .navbar-toggle span) {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}
#topnav .navbar-toggle span:last-child {
  margin-bottom: 0px;
}
#topnav .navbar-toggle.open span {
  position: absolute;
}
#topnav .navbar-toggle.open span:first-child {
  top: 6px;
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#topnav .navbar-toggle.open span:nth-child(2) {
  visibility: hidden;
}
#topnav .navbar-toggle.open span:last-child {
  top: 6px;
  width: 100%;
  --tw-rotate: -45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#topnav .navbar-toggle.open span:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}
#topnav .navbar-toggle:hover,
#topnav .navbar-toggle:focus,
#topnav .navbar-toggle .navigation-menu > li > a:hover {
  background-color: transparent;
}
#topnav .buy-button {
  line-height: 74px;
}
:is([dir='ltr'] #topnav .buy-button) {
  float: right;
}
:is([dir='rtl'] #topnav .buy-button) {
  float: left;
}
#topnav .buy-button > li {
  line-height: initial;
}
#topnav .buy-button .login-btn-primary,
#topnav .buy-button .btn-icon-dark {
  display: none;
}
#topnav .buy-button .login-btn-light,
#topnav .buy-button .btn-icon-light {
  display: inline-block;
}
#topnav .buy-button .search-bar .menu-search form {
  position: relative;
}
#topnav .buy-button .search-bar .searchform::after {
  pointer-events: none !important;
  position: absolute !important;
  inset-inline-end: 14px !important;
  top: 14px !important;
  font-size: 1.25rem !important;
  content: var(--tw-content) !important;
  line-height: 1.25rem !important;
}
#topnav .buy-button .search-bar .searchform input[type='text'] {
  display: block !important;
  height: 2.75rem !important;
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
  -webkit-padding-end: 42px !important;
  padding-inline-end: 42px !important;
  -webkit-padding-start: 0.75rem !important;
  padding-inline-start: 0.75rem !important;
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}
#topnav .buy-button .search-bar .searchform input[type='submit'] {
  display: none;
}
#topnav .buy-button .search-bar .searchform:after {
  content: '󰍉';
  font-family: 'Material Design Icons';
}
#topnav .buy-button .dropdown .dropdown-toggle::after,
#topnav .buy-menu-btn .dropdown .dropdown-toggle::after {
  content: var(--tw-content);
  display: none;
}
#topnav .navigation-menu {
  margin: 0px;
  list-style-type: none;
  padding: 0px;
}
#topnav .navigation-menu > li {
  position: relative;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-left: 6px;
  margin-right: 6px;
  display: block;
}
:is([dir='ltr'] #topnav .navigation-menu > li) {
  float: left;
}
:is([dir='rtl'] #topnav .navigation-menu > li) {
  float: right;
}
#topnav .navigation-menu > li > a {
  display: block;
  background-color: transparent;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 13px;
  text-transform: uppercase;
  line-height: 24px;
  line-height: 1.5rem;
  letter-spacing: 1px;
  --tw-text-opacity: 1;
  color: rgb(22 28 45 / var(--tw-text-opacity));
  font-family: 'montserrat', sans-serif;
  font-weight: 400;
  font-style: normal;
  text-transform: uppercase;
}
:is(.dark #topnav .navigation-menu > li > a) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
#topnav .navigation-menu > li > a:hover,
#topnav .navigation-menu > li > a:active {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}
#topnav .navigation-menu > li:hover > a,
#topnav .navigation-menu > li.active > a {
  --tw-text-opacity: 1 !important;
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
:is(.dark #topnav .navigation-menu > li:hover > a),
:is(.dark #topnav .navigation-menu > li.active > a) {
  --tw-text-opacity: 1 !important;
  font-weight: 700;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
#topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
  white-space: nowrap !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
  font-size: 0.75rem !important;
  line-height: 1rem !important;
  font-weight: 600 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.04em !important;
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}
#topnav .navigation-menu > li .submenu li {
  position: relative;
  margin-left: 0px;
  margin-right: 0px;
}
#topnav .navigation-menu .has-submenu .menu-arrow {
  border-width: 0 2px 2px 0;
  position: absolute;
  inset-inline-end: 0px;
  top: 2rem;
  display: inline-block;
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-border-opacity: 1;
  border-color: rgb(22 28 45 / var(--tw-border-opacity));
  padding: 2px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
}
:is(.dark #topnav .navigation-menu .has-submenu .menu-arrow) {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
:is([dir='ltr'] #topnav .menu-extras) {
  float: right;
}
:is([dir='rtl'] #topnav .menu-extras) {
  float: left;
}
#topnav.scroll {
  border-style: none;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
:is(.dark #topnav.scroll) {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}
#topnav.scroll .navigation-menu > li > a {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
:is(.dark #topnav.scroll .navigation-menu > li > a) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
#topnav.scroll .navigation-menu > li > .menu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
}
:is(.dark #topnav.scroll .navigation-menu > li > .menu-arrow) {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
#topnav.scroll .navigation-menu > li:hover > a,
#topnav.scroll .navigation-menu > li.active > a {
  --tw-text-opacity: 1;
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
#topnav.scroll .navigation-menu > li:hover > .menu-arrow,
#topnav.scroll .navigation-menu > li.active > .menu-arrow {
  --tw-border-opacity: 1;
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
:is(.dark #topnav.scroll .navigation-menu > li:hover > .menu-arrow),
:is(.dark #topnav.scroll .navigation-menu > li.active > .menu-arrow) {
  --tw-border-opacity: 1;
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
#topnav.defaultscroll.dark-menubar .logo {
  line-height: 70px;
}
#topnav.defaultscroll.scroll .logo {
  line-height: 62px;
}
#topnav.nav-sticky {
  background-color: rgb(255 255 255 / 0.8);
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-backdrop-blur: blur(12px);
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
  backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness)
    var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale)
    var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert)
    var(--tw-backdrop-opacity) var(--tw-backdrop-saturate)
    var(--tw-backdrop-sepia);
}
:is(.dark #topnav.nav-sticky) {
  background-color: rgb(0 0 0 / 0.8);
  --tw-shadow-color: #374151;
  --tw-shadow: var(--tw-shadow-colored);
}
#topnav.nav-sticky .navigation-menu.nav-light > li > a {
  --tw-text-opacity: 1;
  color: rgb(22 28 45 / var(--tw-text-opacity));
}
:is(.dark #topnav.nav-sticky .navigation-menu.nav-light > li > a) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  --tw-text-opacity: 1 !important;
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
:is(.dark #topnav.nav-sticky .navigation-menu.nav-light > li.active > a) {
  --tw-text-opacity: 1 !important;
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > .menu-arrow,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > .menu-arrow {
  --tw-border-opacity: 1 !important;
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
#topnav.nav-sticky .navigation-menu.nav-light > li:hover > a,
#topnav.nav-sticky .navigation-menu.nav-light > li.active > a {
  --tw-text-opacity: 1 !important;
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
:is(.dark #topnav.nav-sticky .navigation-menu.nav-light > li:hover > a),
:is(.dark #topnav.nav-sticky .navigation-menu.nav-light > li.active > a) {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  font-weight: 700;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}
#topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow {
  --tw-border-opacity: 1;
  border-color: rgb(22 28 45 / var(--tw-border-opacity));
}
:is(
    .dark #topnav.nav-sticky .navigation-menu.nav-light .has-submenu .menu-arrow
  ) {
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
}
#topnav.nav-sticky.tagline-height {
  top: 0px !important;
}
#topnav.nav-sticky .buy-button .login-btn-primary,
#topnav.nav-sticky .buy-button .btn-icon-dark {
  display: inline-block;
}
#topnav.nav-sticky .buy-button .login-btn-light,
#topnav.nav-sticky .buy-button .btn-icon-light {
  display: none;
}
#topnav.nav-sticky .logo .l-dark {
  display: inline-block;
}
#topnav.nav-sticky .logo .l-light {
  display: none;
}

.logo {
  -webkit-margin-end: 15px;
  margin-inline-end: 15px;
  padding-top: 0px;
  padding-bottom: 0px;
  font-size: 24px;
  font-weight: 600;
  line-height: 68px;
  letter-spacing: 1px;
}

@media (min-width: 1025px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    width: 1116px !important;
  }
}
@media screen and (max-width: 1024px) and (min-width: 992px) {
  #topnav .navigation-menu > li .submenu.megamenu {
    width: 936px !important;
  }
}
@media (min-width: 992px) {
  #topnav .navigation-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
  #topnav .navigation-menu > li .submenu {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
    visibility: hidden;
    position: absolute;
    top: 100%;
    inset-inline-start: 0px;
    z-index: 1000;
    margin-top: 10px;
    min-width: 180px;
    list-style-type: none;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 0px;
    padding-right: 0px;
    opacity: 0;
    --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
    --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  :is(.dark #topnav .navigation-menu > li .submenu) {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    --tw-shadow-color: #374151;
    --tw-shadow: var(--tw-shadow-colored);
  }
  #topnav .navigation-menu > li .submenu li {
    position: relative;
  }
  #topnav .navigation-menu > li .submenu li a {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    clear: both;
    display: block;
    white-space: nowrap;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    font-size: 16px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.04em;
    --tw-text-opacity: 1;
    color: rgb(22 28 45 / var(--tw-text-opacity));
  }
  :is(.dark #topnav .navigation-menu > li .submenu li a) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li .submenu li a:hover {
    --tw-text-opacity: 1 !important;
    color: rgb(22 163 74 / var(--tw-text-opacity)) !important;
  }
  :is(.dark #topnav .navigation-menu > li .submenu li a:hover) {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu > li .submenu li ul {
    margin: 0px;
    list-style-type: none;
    -webkit-padding-start: 0px;
    padding-inline-start: 0px;
  }
  #topnav .navigation-menu > li .submenu.megamenu {
    position: fixed;
    inset-inline-start: 50%;
    top: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
  }
  :is([dir='ltr'] #topnav .navigation-menu > li .submenu.megamenu) {
    --tw-translate-x: -50%;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  :is([dir='rtl'] #topnav .navigation-menu > li .submenu.megamenu) {
    --tw-translate-x: 50%;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #topnav .navigation-menu > li .submenu.megamenu > li {
    width: 20%;
    overflow: hidden;
    vertical-align: top;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li .submenu {
    inset-inline-start: 100%;
    top: 0px;
    -webkit-margin-start: 10px;
    margin-inline-start: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li .submenu > li .submenu {
    inset-inline-start: 101%;
    top: 0px;
    -webkit-margin-start: 10px;
    margin-inline-start: 10px;
    margin-top: -1px;
  }
  #topnav .navigation-menu > li > a {
    min-height: 62px;
    padding-top: 25px;
    padding-bottom: 25px;
  }
  #topnav .navigation-menu > li:hover > .menu-arrow {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-border-opacity));
  }
  :is(.dark #topnav .navigation-menu > li:hover > .menu-arrow) {
    --tw-border-opacity: 1;
    border-color: rgb(22 163 74 / var(--tw-border-opacity));
  }
  #topnav .navigation-menu > li:hover > a,
  #topnav .navigation-menu > li.active > a {
    --tw-text-opacity: 1 !important;
  }
  :is(.dark #topnav .navigation-menu > li:hover > a),
  :is(.dark #topnav .navigation-menu > li.active > a) {
    --tw-text-opacity: 1 !important;
  }
  #topnav .navigation-menu > li.last-elements .submenu {
    inset-inline-start: auto;
    inset-inline-end: 0px;
  }
  #topnav .navigation-menu > li.last-elements .submenu::before {
    inset-inline-start: auto;
    content: var(--tw-content);
    inset-inline-end: 10px;
  }
  #topnav
    .navigation-menu
    > li.last-elements
    .submenu
    > li.has-submenu
    .submenu {
    inset-inline-start: auto;
    inset-inline-end: 100%;
    -webkit-margin-start: 0px;
    margin-inline-start: 0px;
    -webkit-margin-end: 10px;
    margin-inline-end: 10px;
  }
  #topnav .navigation-menu.nav-light > li > a {
    color: rgb(255 255 255 / 0.5);
  }
  #topnav .navigation-menu.nav-light > li.active > a {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu.nav-light > li:hover > .menu-arrow {
    --tw-border-opacity: 1 !important;
    border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
  }
  #topnav .navigation-menu.nav-light > li:hover > a {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu.nav-light .has-submenu .menu-arrow {
    border-color: rgb(255 255 255 / 0.5);
  }
  #topnav .navigation-menu.nav-light .has-submenu.active .menu-arrow {
    --tw-border-opacity: 1 !important;
    border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
  }
  #topnav .buy-button {
    -webkit-margin-start: 15px;
    margin-inline-start: 15px;
    -webkit-padding-start: 15px;
    padding-inline-start: 15px;
  }
  #topnav .navbar-toggle {
    display: none;
  }
  #topnav #navigation {
    display: block !important;
  }
  #topnav.scroll {
    top: 0px;
  }
  #topnav.scroll .navigation-menu > li > a {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  #topnav.scroll-active .navigation-menu > li > a {
    padding-top: 25px;
    padding-bottom: 25px;
  }
}
@media (max-width: 991px) {
  #topnav {
    min-height: 74px;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
    --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
    --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  :is(.dark #topnav) {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
    --tw-shadow-color: #374151;
    --tw-shadow: var(--tw-shadow-colored);
  }
  #topnav .logo .l-dark {
    display: inline-block !important;
  }
  #topnav .logo .l-light {
    display: none !important;
  }
  #topnav .container {
    width: auto;
  }
  #topnav #navigation {
    max-height: 400px;
    --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
    --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
    -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }
  #topnav .navigation-menu {
    float: none !important;
  }
  #topnav .navigation-menu > li {
    float: none !important;
  }
  #topnav .navigation-menu > li .submenu {
    margin: 0px;
    display: none;
    list-style-type: none;
    -webkit-padding-start: 1.25rem;
    padding-inline-start: 1.25rem;
  }
  #topnav .navigation-menu > li .submenu li a {
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    position: relative !important;
    display: block !important;
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    padding-left: 15px !important;
    padding-right: 15px !important;
    letter-spacing: 0.04em !important;
    --tw-text-opacity: 1 !important;
    color: rgb(22 28 45 / var(--tw-text-opacity)) !important;
  }
  :is(.dark #topnav .navigation-menu > li .submenu li a) {
    --tw-text-opacity: 1 !important;
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }
  #topnav .navigation-menu > li .submenu.megamenu li .megamenu-head {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 15px;
    padding-right: 15px;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul {
    list-style-type: none;
    -webkit-padding-start: 0px;
    padding-inline-start: 0px;
  }
  #topnav .navigation-menu > li .submenu.megamenu > li > ul > li > span {
    position: relative;
    display: block;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
    letter-spacing: 2px;
    --tw-text-opacity: 1;
    color: rgb(226 232 240 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li .submenu .submenu {
    display: none;
    list-style-type: none;
  }
  #topnav .navigation-menu > li .submenu .submenu.open {
    display: block;
  }
  #topnav .navigation-menu > li > a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
    --tw-text-opacity: 1;
    color: rgb(22 28 45 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li > a::after {
    position: absolute;
    content: var(--tw-content);
    inset-inline-end: 15px;
  }
  :is(.dark #topnav .navigation-menu > li > a) {
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity));
  }
  #topnav .navigation-menu > li > a:hover,
  #topnav .navigation-menu > li .submenu li a:hover,
  #topnav .navigation-menu > li.has-submenu.open > a {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity));
  }
  #topnav .menu-extras .menu-item {
    --tw-border-opacity: 1;
    border-color: rgb(229 231 235 / var(--tw-border-opacity));
  }
  :is(.dark #topnav .menu-extras .menu-item) {
    --tw-border-opacity: 1;
    border-color: rgb(55 65 81 / var(--tw-border-opacity));
  }
  :is([dir='ltr'] #topnav .navbar-header) {
    float: left;
  }
  :is([dir='rtl'] #topnav .navbar-header) {
    float: right;
  }
  #topnav .buy-button .login-btn-primary,
  #topnav .buy-button .btn-icon-dark {
    display: inline-block !important;
  }
  #topnav .buy-button .login-btn-light,
  #topnav .buy-button .btn-icon-light {
    display: none;
  }
  #topnav .has-submenu .submenu .submenu-arrow {
    position: absolute;
    inset-inline-end: 1.25rem;
    top: 0.75rem;
    --tw-rotate: 45deg;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y))
      rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
      scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }
  #topnav .has-submenu.active a {
    --tw-text-opacity: 1;
    color: rgb(22 163 74 / var(--tw-text-opacity));
  }

  #navigation {
    position: absolute;
    top: 74px;
    inset-inline-start: 0px;
    display: none;
    height: auto;
    width: 100%;
    overflow: auto;
    --tw-bg-opacity: 1;
    background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  }

  :is(.dark #navigation) {
    --tw-bg-opacity: 1;
    background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  }
  #navigation.open {
    display: block;
    overflow-y: auto;
  }
}
@media (max-width: 768px) {
  #topnav .navigation-menu .has-submenu .menu-arrow {
    inset-inline-end: 0.5rem;
    top: 1rem;
  }
}
@media (min-width: 768px) {
  #topnav .navigation-menu > li.has-submenu:hover > .submenu {
    visibility: visible;
    margin-top: 0px;
    opacity: 1;
  }
  #topnav
    .navigation-menu
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu {
    visibility: visible;
    -webkit-margin-start: 0px;
    margin-inline-start: 0px;
    -webkit-margin-end: 0px;
    margin-inline-end: 0px;
    opacity: 1;
  }
  #topnav
    .navigation-menu
    > li.has-submenu:hover
    > .submenu
    > li.has-submenu:hover
    > .submenu
    > li:hover
    > .submenu {
    visibility: visible;
    -webkit-margin-start: 0px;
    margin-inline-start: 0px;
    -webkit-margin-end: 0px;
    margin-inline-end: 0px;
    opacity: 1;
  }

  .navbar-toggle {
    display: block;
  }
}
@media (max-width: 425px) {
  #topnav .buy-menu-btn {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 10px !important;
    margin-right: 10px !important;
    display: block !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 1.25rem !important;
    padding-right: 1.25rem !important;
  }
  #topnav .buy-menu-btn .dropdown .dropdown-menu.show {
    -webkit-transform: translate3d(0px, -54px, 0px) !important;
    transform: translate3d(0px, -54px, 0px) !important;
  }
}
.tagline {
  position: absolute;
  z-index: 99;
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-left: 0px;
  padding-right: 0px;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

@media screen and (max-width: 575px) {
  .tagline {
    display: none;
  }

  .tagline-height {
    top: 0px !important;
  }
}
@media (min-width: 576px) {
  .tagline-height {
    top: 47px !important;
  }
}
.sidebar-nav {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 0px;
  padding-right: 0px;
}
.sidebar-nav > .navbar-item {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 15px;
  padding-right: 15px;
}
.sidebar-nav > .navbar-item .navbar-link {
  font-size: 15px;
  font-weight: 600;
}
.sidebar-nav > .navbar-item .navbar-link .navbar-icon {
  -webkit-margin-end: 6px;
  margin-inline-end: 6px;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.sidebar-nav > .navbar-item:hover .navbar-link,
.sidebar-nav > .navbar-item.active .navbar-link {
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}
.sidebar-nav li.active a {
  --tw-text-opacity: 1;
}
.sidebar-nav li.account-menu.active .navbar-link,
.sidebar-nav li.account-menu:hover .navbar-link {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity)) !important;
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}
:is(.dark .sidebar-nav li.account-menu.active .navbar-link),
:is(.dark .sidebar-nav li.account-menu:hover .navbar-link) {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

/* // _footer.scss */
/*********************************/
/*         Footer                */
/*===============================*/
.footer .foot-subscribe input::-webkit-input-placeholder {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.footer .foot-subscribe input::-moz-placeholder {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.footer .foot-subscribe input:-ms-input-placeholder {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.footer .foot-subscribe input::-ms-input-placeholder {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}
.footer .foot-subscribe input::placeholder {
  --tw-text-opacity: 1;
  color: rgb(229 231 235 / var(--tw-text-opacity));
}

/*********************************/
/*             Helper            */
/*===============================*/
.container {
  position: relative;
}

/* Preloader */
#preloader {
  background-image: linear-gradient(45deg, #ffffff, #ffffff);
  z-index: 99999;
  position: fixed;
  inset: 0px;
}
#preloader #status {
  position: absolute;
  inset-inline-start: 0px;
  inset-inline-end: 0px;
  top: 50%;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
#preloader #status .spinner {
  position: relative;
  margin-top: 100px;
  margin-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  height: 2.5rem;
  width: 2.5rem;
}
#preloader #status .spinner .double-bounce1,
#preloader #status .spinner .double-bounce2 {
  position: absolute;
  top: 0px;
  inset-inline-start: 0px;
  height: 100%;
  width: 100%;
  border-radius: 9999px;
  background-color: rgb(22 163 74 / 0.6);
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}
#preloader #status .spinner .double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
/* Shapes */
.shape {
  position: absolute;
  inset-inline-end: 0px;
  bottom: -1px;
  inset-inline-start: 0px;
}
.shape > svg {
  height: auto;
  width: 100%;
  -webkit-transform: scale(2);
  transform: scale(2);
  -webkit-transform-origin: top center;
  transform-origin: top center;
}

@media (max-width: 425px) {
  .shape {
    bottom: -2px;
  }
}
/* Switcher */
.label .ball {
  -webkit-transition: -webkit-transform 0.2s linear;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
  --tw-translate-x: 0px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.checkbox:checked + .label .ball {
  --tw-translate-x: 1.5rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

/*********************************/
/*         Tobii Lightbox        */
/*===============================*/
/* Tobii Lighbox */
.tobii > button.tobii__close svg,
.tobii > button.tobii__prev svg,
.tobii > button.tobii__next svg {
  height: 2.25rem;
  width: auto;
}

.tobii__counter {
  font-size: 17px;
  line-height: 24px;
}

.tobii-zoom {
  display: block;
}

.tobii-zoom__icon {
  display: none;
}

#grid {
  padding: 0px;
}

/*********************************/
/*             Home              */
/*===============================*/
/* Kenburn Effect */
.zoom-image {
  position: absolute;
  inset: 0px;
  overflow: hidden;
}

.image-wrap {
  position: absolute;
  top: -350px;
  bottom: -350px;
  inset-inline-start: -100px;
  inset-inline-end: -100px;
  margin: auto;
  height: auto;
  min-height: 100%;
  width: auto;
  min-width: 100%;
  overflow: hidden;
  -webkit-animation: 100s ppb_kenburns linear infinite alternate;
  animation: 100s ppb_kenburns linear infinite alternate;
}

@-webkit-keyframes move {
  0% {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
}

@keyframes move {
  0% {
    -webkit-transform-origin: bottom;
    transform-origin: bottom;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
}
@-webkit-keyframes ppb_kenburns {
  0% {
    -webkit-transform: scale(1.3) translate(-10%, 10%);
    transform: scale(1.3) translate(-10%, 10%);
  }
  25% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
  50% {
    -webkit-transform: scale(1.3) translate(10%, 10%);
    transform: scale(1.3) translate(10%, 10%);
  }
  75% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
  100% {
    -webkit-transform: scale(1.3) translate(-10%, 10%);
    transform: scale(1.3) translate(-10%, 10%);
  }
}
@keyframes ppb_kenburns {
  0% {
    -webkit-transform: scale(1.3) translate(-10%, 10%);
    transform: scale(1.3) translate(-10%, 10%);
  }
  25% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
  50% {
    -webkit-transform: scale(1.3) translate(10%, 10%);
    transform: scale(1.3) translate(10%, 10%);
  }
  75% {
    -webkit-transform: scale(1) translate(0, 0);
    transform: scale(1) translate(0, 0);
  }
  100% {
    -webkit-transform: scale(1.3) translate(-10%, 10%);
    transform: scale(1.3) translate(-10%, 10%);
  }
}
/*********************************/
/*         Testimonial           */
/*===============================*/
.client-testi {
  cursor: e-resize;
}
.client-testi .content::before {
  position: absolute;
  top: 2rem;
  inset-inline-start: 0px;
  -webkit-margin-start: 0.75rem;
  margin-inline-start: 0.75rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform-origin: 0;
  transform-origin: 0;
  --tw-rotate: 135deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-width: 8px;
  border-style: solid;
  border-top-color: transparent;
  border-inline-end-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
  border-inline-start-color: transparent;
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-content: '';
  content: var(--tw-content);
}
:is(.dark .client-testi .content)::before {
  border-inline-end-color: rgb(0 0 0 / var(--tw-border-opacity));
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-bottom-color: rgb(0 0 0 / var(--tw-border-opacity));
}

.customer-testi {
  cursor: e-resize;
}
.customer-testi .content::before {
  position: absolute;
  inset-inline-start: 50%;
  bottom: -4px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-width: 8px;
  border-top-color: transparent;
  border-inline-end-color: rgb(255 255 255 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity));
  border-inline-start-color: transparent;
  --tw-shadow: 2px 2px 2px -1px rgb(60 72 88 / 0.15);
  --tw-shadow-colored: 2px 2px 2px -1px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-content: '';
  content: var(--tw-content);
}
:is(.dark .customer-testi .content)::before {
  border-inline-end-color: rgb(0 0 0 / var(--tw-border-opacity));
  --tw-border-opacity: 1;
  border-bottom-color: rgb(0 0 0 / var(--tw-border-opacity));
  content: var(--tw-content);
  --tw-shadow-color: #374151;
  --tw-shadow: var(--tw-shadow-colored);
}
.customer-testi .content:before {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

.tns-nav {
  margin-top: 0.75rem;
  text-align: center;
}
.tns-nav button {
  margin: 0.25rem;
  border-radius: 9999px;
  border-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(255 255 255 / var(--tw-border-opacity));
  background-color: rgb(22 28 45 / 0.2);
  padding: 2px;
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
:is(.dark .tns-nav button) {
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  background-color: rgb(255 255 255 / 0.2);
}
.tns-nav button.tns-nav-active {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
}

/* Tns control */
.tns-controls button[data-controls='prev'],
.tns-controls button[data-controls='next'] {
  position: absolute;
  top: 50%;
  z-index: 10;
  height: 2rem;
  width: 2rem;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-radius: 9999px;
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  font-size: 17px;
  line-height: 24px;
  --tw-text-opacity: 1;
  color: rgb(60 72 88 / var(--tw-text-opacity));
  --tw-shadow: 0 5px 13px rgb(60 72 88 / 0.2);
  --tw-shadow-colored: 0 5px 13px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  -webkit-transition-property: all;
  transition-property: all;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
:is(.dark .tns-controls button[data-controls='prev']),
:is(.dark .tns-controls button[data-controls='next']) {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow-color: #374151;
  --tw-shadow: var(--tw-shadow-colored);
}
.tns-controls button[data-controls='prev']:hover,
.tns-controls button[data-controls='next']:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.tns-controls button[data-controls='prev'] {
  inset-inline-start: 0px;
}
.tns-controls button[data-controls='next'] {
  inset-inline-end: 0px;
}

/* RTL */
html[dir='rtl'] .tns-inner,
html[dir='rtl'] .tns-nav {
  direction: ltr !important;
}
html[dir='rtl'] .tns-visually-hidden {
  left: 0 !important;
  visibility: hidden;
}

/*********************************/
/*           Choices             */
/*===============================*/
.filter-search-form .icons {
  position: absolute;
  top: 48%;
  inset-inline-start: 0.75rem;
  z-index: 1;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  font-size: 20px;
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

.registration-form {
  position: relative;
}
/* .registration-form .submit-btn {
  border-radius: 0.5rem;
}
.registration-form .form-input,
.registration-form .choices[data-type*='select-one'] .choices__inner {
  border-radius: 0.5rem;
} */

@media (min-width: 992px) {
  /* .filter-border::before {
    position: absolute;
    top: 10px;
    inset-inline-end: 0px;
    z-index: 1;
    height: 2.5rem;
    border-radius: 0.375rem;
    border-right-width: 1px;
    border-color: inherit;
    outline-width: 0px;
    --tw-content: '';
    content: var(--tw-content);
  } */
  :is(.dark .filter-border)::before {
    content: var(--tw-content);
    --tw-border-opacity: 1;
  }

  .registration-form {
    position: relative;
  }
  .registration-form .submit-btn {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-start-end-radius: 0.125rem;
    border-end-end-radius: 0.125rem;
    border-bottom-right-radius: 0.125rem;
    border-bottom-left-radius: 0.125rem;
    border-start-start-radius: 0px;
    border-end-start-radius: 0px;
  }
  /* .registration-form .form-input {
    border-top-left-radius: 0.125rem;
    border-top-right-radius: 0.125rem;
    border-start-end-radius: 0px;
    border-end-end-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-start-start-radius: 0.125rem;
    border-end-start-radius: 0.125rem;
    outline-width: 0px;
  } */
  .registration-form .choices[data-type*='select-one'] .choices__inner {
    border-radius: 0px;
  }
}
.filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
  height: 60px;
  border-radius: 0px;
  border-width: 0px;
  padding-top: 13px;
  -webkit-padding-end: 6px;
  padding-inline-end: 6px;
  padding-bottom: 15px;
  -webkit-padding-start: 45px;
  padding-inline-start: 45px;
  font-size: 15px;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
:is([dir='rtl'] .filter-input-box),
:is([dir='rtl'] .filter-input-box.form-select),
:is([dir='rtl'] .filter-search-form .choices__inner) {
  text-align: right;
}

.choices__inner {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
}

:is(.dark .choices__inner) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
}

.choices__list--dropdown,
.choices[data-type*='select-one'] .choices__input {
  z-index: 999;
  /* border-radius: 5px; */
  border-width: 0px;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-shadow: 0 0 3px rgb(60 72 88 / 0.15);
  --tw-shadow-colored: 0 0 3px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

:is(.dark .choices__list--dropdown),
:is(.dark .choices[data-type*='select-one'] .choices__input) {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-shadow-color: #1f2937;
  --tw-shadow: var(--tw-shadow-colored);
}

.choices__list--dropdown .choices__item--selectable.is-highlighted {
  --tw-bg-opacity: 1;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(22 28 45 / var(--tw-text-opacity));
}

:is(.dark .choices__list--dropdown .choices__item--selectable.is-highlighted) {
  --tw-bg-opacity: 1;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.choices__list,
.choices__list--dropdown .choices__item--selectable {
  --tw-text-opacity: 1;
  color: rgb(148 163 184 / var(--tw-text-opacity));
}

.choices__list--dropdown,
.choices__list[aria-expanded] {
  z-index: 2;
  border-width: 0px;
}

.choices[data-type*='select-one']::after {
  top: 1.25rem;
  inset-inline-end: 1.75rem;
  inset-inline-start: auto;
  border-width: 0px;
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

:is(.dark .choices[data-type*='select-one'])::after {
  content: var(--tw-content);
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.choices[data-type*='select-one']:after {
  content: '󰅀' !important;
  font-family: 'Material Design Icons';
}

/*********************************/
/*         Countdown             */
/*===============================*/
#countdown .count-down .count-number {
  height: 130px;
  width: 130px;
  border-radius: 9999px;
  background-color: rgb(255 255 255 / 0.1);
  font-size: 40px;
  line-height: 110px;
  --tw-shadow: 0 5px 13px rgb(60 72 88 / 0.2);
  --tw-shadow-colored: 0 5px 13px var(--tw-shadow-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
#countdown .count-down .count-head {
  position: relative;
  display: block;
  --tw-translate-y: -25px;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* //maintenance */
#maintenance .indicator {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

/*********************************/
/*             Contact           */
/*===============================*/
.form-input {
  height: 2.5rem;
  width: 100%;
  /* border-radius: 0.25rem; */
  border-width: 1px;
  border-color: inherit;
  background-color: transparent;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
:is(.dark .form-input) {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(26 32 40 / var(--tw-text-opacity));
}
.form-input:focus {
  --tw-border-opacity: 1;
  border-color: rgb(22 163 74 / var(--tw-border-opacity));
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow, 0 0 #0000);
}
.form-input.textarea {
  height: 7rem;
}

/* Validation */
.error {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0px;
  margin-right: 0px;
  display: none;
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

#ajaxsuccess {
  clear: both;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-left: 0px;
  margin-right: 0px;
  display: none;
  width: 100%;
  font-size: 17px;
  line-height: 24px;
}

.error_message,
#success_page {
  margin-bottom: 1.25rem;
  /* border-radius: 0.375rem; */
  padding: 0.625rem;
  text-align: center;
}

.error_message {
  background-color: rgb(220 38 38 / 0.05);
  --tw-text-opacity: 1;
  color: rgb(220 38 38 / var(--tw-text-opacity));
}

.contact-loader {
  display: none;
}

#success_page {
  background-color: rgb(22 163 74 / 0.05);
  --tw-text-opacity: 1;
  color: rgb(22 163 74 / var(--tw-text-opacity));
}

/*****************/
/* Subscribe Form */
/*****************/
.subcribe-form form input {
  height: 50px;
  width: 100%;
  padding-top: 1rem;
  -webkit-padding-end: 10rem;
  padding-inline-end: 10rem;
  padding-bottom: 1rem;
  -webkit-padding-start: 1.5rem;
  padding-inline-start: 1.5rem;
  --tw-text-opacity: 1;
  color: rgb(22 28 45 / var(--tw-text-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
}
:is(.dark .subcribe-form form input) {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}
.subcribe-form form .btn {
  position: absolute;
  top: 2px;
  inset-inline-end: 3px;
  height: 46px;
}

/*****************/
/* Job search form */
/*****************/
.filter-input-box,
.filter-input-box.form-select,
.filter-search-form .choices__inner {
  height: 60px;
  border-radius: 0px;
  border-width: 0px;
  padding-top: 13px;
  -webkit-padding-end: 6px;
  padding-inline-end: 6px;
  padding-bottom: 15px;
  -webkit-padding-start: 45px;
  padding-inline-start: 45px;
  font-size: 15px;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  -webkit-box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}
.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 12px;
  padding-left: 12px;
}
@media (min-width: 540px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 640px) {
  .container {
    max-width: 640px;
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 992px;
  }
}
@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
    padding-right: 45px;
    padding-left: 45px;
  }
}
@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
    padding-right: 5rem;
    padding-left: 5rem;
  }
}
@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
    padding-right: 13rem;
    padding-left: 13rem;
  }
}

@media (min-width: 2500px) {
  .container {
    max-width: 70%;
    padding-right: 20rem;
    padding-left: 20rem;
  }
}

.form-input,
.form-textarea,
.form-select,
.form-multiselect {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}
.form-input:focus,
.form-textarea:focus,
.form-select:focus,
.form-multiselect:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  border-color: #2563eb;
}
.form-input::-webkit-input-placeholder,
.form-textarea::-webkit-input-placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-input::-moz-placeholder,
.form-textarea::-moz-placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-input:-ms-input-placeholder,
.form-textarea:-ms-input-placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-input::-ms-input-placeholder,
.form-textarea::-ms-input-placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-input::placeholder,
.form-textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}
.form-input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}
.form-input::-webkit-date-and-time-value {
  min-height: 1.5em;
}
.form-input::-webkit-datetime-edit,
.form-input::-webkit-datetime-edit-year-field,
.form-input::-webkit-datetime-edit-month-field,
.form-input::-webkit-datetime-edit-day-field,
.form-input::-webkit-datetime-edit-hour-field,
.form-input::-webkit-datetime-edit-minute-field,
.form-input::-webkit-datetime-edit-second-field,
.form-input::-webkit-datetime-edit-millisecond-field,
.form-input::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}
.form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}
.form-select:where([size]:not([size='1'])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
  print-color-adjust: unset;
}
.form-checkbox,
.form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}
.form-checkbox {
  border-radius: 0px;
}
.form-checkbox:focus,
.form-radio:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty, /*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0
    var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0
    calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  -webkit-box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow),
    var(--tw-shadow);
}
.form-checkbox:checked,
.form-radio:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}
.form-checkbox:checked:hover,
.form-checkbox:checked:focus,
.form-radio:checked:hover,
.form-radio:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}
.form-checkbox:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.form-checkbox:indeterminate:hover,
.form-checkbox:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}

.black-n-white {
  background-color: #000;
  color: #fff;
}
:is(.dark .black-n-white) {
  background-color: #fff;
  color: #000;
}
